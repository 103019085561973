import moment from "moment";
import {encryption} from "../../../../../resources/app/utils/encryption.js";
import axios from "axios";

(() => {
    'use strict'

    const form = document.querySelector('.needs-validation');
    const phoneInput = document.querySelector('input.phoneInput');
    const whatsappInput = document.querySelector('input.whatsappInput');
    const datesInput = document.querySelector('input.datesInput');
    const departureInput = document.querySelector('input.departureInput');

    function setPhoneNumber(value) {
        return value.replace(/[^0-9()+\-, ]/g, '');
    }

    if(phoneInput) {
        phoneInput.addEventListener('input', function() {
            this.value = setPhoneNumber(this.value);
        });
    }

    if(whatsappInput) {
        whatsappInput.addEventListener('input', function() {
            this.value = setPhoneNumber(this.value);
        });
    }

    if(datesInput) {
        $(datesInput).daterangepicker({
            autoUpdateInput: false,
            singleDatePicker: true,
            parentEl: '.scroll-fix',
            minDate: moment().add(3, 'days'),  // 3 days after the current date
            opens: 'left',
            autoApply: true,  // Automatically apply the date when selected
            showDropdowns: true,  // Display the dropdown for selecting year/month
            locale: {
                format: 'DD MMMM YYYY',
                cancelLabel: null,  // Remove the 'Clear' button
            },
            alwaysShowCalendars: true,  // Show the calendars
            linkedCalendars: true,  // Allow showing two months separately
            numberOfMonths: 2,  // Show two months
        });

        $(datesInput).on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD MMMM YYYY'));
            $(departureInput).val(picker.startDate.format('YYYY-MM-DD'));
        });

        $(datesInput).on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            $(departureInput).val('');
        });
    }

    if(form) {
        form.addEventListener('submit', event => {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();

                const qtyInput = form.querySelector('input.qtyInput[name="adult"]');
                if(qtyInput) {
                    const panelDropdown = qtyInput.closest('.panel-dropdown');

                    if(!isNaN(parseInt(qtyInput.value))) {
                        panelDropdown.classList.add('is-invalid');
                    } else if(parseInt(qtyInput.value)<=0) {
                        panelDropdown.classList.add('is-invalid');
                    } else {
                        panelDropdown.classList.remove('is-invalid');
                    }
                }
            }

            form.classList.add('was-validated')
        }, false);
    }

    const checkSPA = ()=>{
        const lskop = import.meta.env.VITE_LS_KEY;
        const lsgeo = () => {
            const encrypted = new encryption();
            const lsGeoKeyValue = window.localStorage.getItem("gkv");
            let lsKey = window.localStorage.getItem("lsk");
            if(lsKey && lsGeoKeyValue) {
                lsKey = encrypted.decrypt(lsKey, lskop);
                return JSON.parse(encrypted.decrypt(lsGeoKeyValue, lsKey));
            } else {
                return null;
            }
        }

        const btok = () => {
            const secret = lsgeo();
            if(secret) {
                return secret.t;
            } else {
                return null;
            }
        }

        const gid = () => {
            const secret = lsgeo();
            if(secret) {
                return secret.i;
            } else {
                return null;
            }
        }

        const token = btok();
        const instance = axios.create({
            baseURL: window.location.origin + '/api',
            withCredentials : true,
            timeout: (1000*60),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Authorization': `Bearer ${token}`,
                'app-gid': gid(),
            }
        });

        const setSignIn=(elul)=>{
            let newel;

            if(import.meta.env.VITE_REGISTER==='true') {
                newel = window.document.createElement('li');
                newel.innerHTML = `<span><a href="${window.location.origin}/app/register">Register</a></span>`;
                newel.classList.add('mm-register');
                elul.appendChild(newel);
            }

            newel = window.document.createElement('li');
            newel.innerHTML = `<span><a href="${window.location.origin}/app/login">Sign In</a></span>`;
            newel.classList.add('mm-login');
            elul.appendChild(newel);
        };

        const setAccount=(elul)=>{
            let newel;
            newel = window.document.createElement('li');
            newel.innerHTML = `<span><a href="${window.location.origin}/app">My Account</a></span>`;
            newel.classList.add('mm-account');
            elul.appendChild(newel);
            newel = window.document.createElement('li');
            newel.innerHTML = `<span><a href="javascript:void(0)">Sign Out</a></span>`;
            newel.classList.add('mm-logout');
            elul.appendChild(newel);
            newel.querySelector('a').addEventListener('click', async event => {
                const instance = axios.create({
                    withCredentials: true,
                    timeout: (1000 * 60),
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true
                    }
                });
                await instance.get("/sanctum/csrf-cookie");
                instance.post('/logout', {gid: lsgeo().i}).then(response=>{
                    const encrypted = new encryption();
                    const lsGeoKeyValue = window.localStorage.getItem("gkv");
                    let lsKey = window.localStorage.getItem("lsk");
                    if(lsKey && lsGeoKeyValue) {
                        lsKey = encrypted.decrypt(lsKey, lskop);
                        const secret = JSON.parse(encrypted.decrypt(lsGeoKeyValue, lsKey));
                        secret.t = null;
                        window.localStorage.setItem("gkv", encrypted.encrypt(JSON.stringify(secret), lsKey));
                        window.localStorage.setItem("guest", "isGuest");
                        window.location.href = window.location.origin;
                    }
                });
            });
        };

        const elul = window.document.querySelector('#menu ul');
        const mmul = window.document.querySelector('#mm-menu #mm-0 ul.mm-listview');
        if(elul) {
            if(token) {
                instance.get('/user/auth').then(response=>{
                    setAccount(elul);
                    if(mmul) {
                        setAccount(mmul);
                    }
                }).catch(error=>{
                    setSignIn(elul);
                    if(mmul) {
                        setSignIn(mmul);
                    }
                });
            } else {
                setSignIn(elul);
                if(mmul) {
                    setSignIn(mmul);
                }
            }
        }
    }

    setTimeout(()=>{
        checkSPA();
    }, 250);

})()
